<template>
  <v-col class="mx-auto" style="max-width: 1000px;">
    <h3>Promocoding</h3>

    <div class="block">
      <v-btn
        @click="startCreatePromocode"
        depressed
        class="ma-1"
      >
        Create promocode
      </v-btn>
<!--      <v-btn-->
<!--        @click="startGeneratePromocode"-->
<!--        depressed-->
<!--        class="ma-1"-->
<!--      >-->
<!--        Generate promocodes-->
<!--      </v-btn>-->
    </div>

    <v-data-table
      :headers="headers"
      :loading="!promocodesData"
      :items="promocodesData || []"
      :custom-sort="sort"
      disable-pagination
      hide-default-footer
      class="block elevation-1"
    >
      <template v-slot:header.price="{ header }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-inline-flex">
              <span>TPC</span>
              <v-btn icon v-bind="attrs" v-on="on" x-small>
                <v-icon x-small>mdi-information-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ header.text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:header.time_from_registration="{ header }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-inline-flex">
              <span>HFS</span>
              <v-btn icon v-bind="attrs" v-on="on" x-small>
                <v-icon x-small>mdi-information-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ header.text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.menu="{ item }">
        {{ getMenuName(item.menu_id) }}
      </template>
      <template v-slot:item.active="{ item }">
        {{ item.active === 't' ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <template v-if="$vuetify.breakpoint.xs">
          <v-btn class="mr-2" depressed @click="startEditItem(item)">
            edit
          </v-btn>
          <v-btn depressed @click="deleteItem(item)">
            remove
          </v-btn>
        </template>
        <template v-else>
          <v-icon
            small
            class="mr-2"
            @click="startEditItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </template>
      <template v-slot:no-data>
        No data
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogPromocode"
      persistent
      max-width="350px"
    >
      <v-card class="card-form">
        <v-card-title>
          <span class="text-h5">{{ editDialogTitle }}</span>
          <v-spacer/>
          <v-btn icon @click="dialogPromocode = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="editPromocode">
          <div class="d-flex mb-4" v-if="editPromocode.id">
            <h4 class="mr-2">ID</h4>
            <span>{{editPromocode.id}}</span>
          </div>
          <v-autocomplete
            :items="$store.AppStore.menus"
            v-model="editPromocode.menu_id"
            label="Recipes set"
            auto-select-first
            clearable
            outlined
            dense
            item-value="id"
            item-text="name"
            :disabled="!$store.AppStore.menus"
            hide-details
            class="mt-2"
          />
          <v-text-field
            v-model="editPromocode.promocode"
            label="Promocode"
            outlined
            dense
            hide-details
          />
          <div class="d-flex">
            <h4 class="ma-2">Active</h4>
            <v-btn-toggle
              v-model="editPromocode.active"
              mandatory
              dense
            >
              <v-btn>Yes</v-btn>
              <v-btn>No</v-btn>
            </v-btn-toggle>
          </div>
          <v-text-field
            v-model="editPromocode.start_date"
            label="Start date"
            outlined
            dense
            hide-details
            readonly
            @click="openDatePicker('start')"
          />
          <v-text-field
            v-model="editPromocode.end_date"
            label="End date"
            outlined
            dense
            hide-details
            @click="openDatePicker('end')"
          />
          <v-text-field
            v-model="editPromocode.total_price_coef"
            label="Total price coef"
            type="number"
            outlined
            dense
            hide-details
          />
          <v-text-field
            v-model="editPromocode.hours_from_registration"
            :label="$vuetify.breakpoint.smAndDown ? 'Reg hours' : 'Hours from registration'"
            type="number"
            outlined
            dense
            hide-details
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            @click="dialogPromocode = false"
            text
            color="primary"
          >
            cancel
          </v-btn>
          <v-btn
            @click="updatePromocode"
            outlined
            color="primary"
          >
            {{ editButtonTitle }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogGeneratePromocode"
      persistent
      max-width="350px"
    >
      <v-card class="card-form">
        <v-card-title>
          <span class="text-h5">Generation</span>
          <v-spacer/>
          <v-btn icon @click="dialogGeneratePromocode = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="newGeneration">
          <v-container>
            <v-text-field
              v-model="newGeneration.prefix"
              label="Code prefix"
              outlined
              dense
              hide-details
            />
            <v-text-field
              v-model="newGeneration.salt_size"
              label="Code salt size"
              outlined
              dense
              hide-details
            />
            <v-text-field
              v-model="newGeneration.total_price_coef"
              label="Total price coef"
              type="number"
              outlined
              dense
              hide-details
            />
            <div
              v-for="(d, i) of monthsPicker"
              :key="`month-picker-${i}`"
            >
              <v-row class="ma-0">
                <div>
                  {{
                    new Date(d).toLocaleString('default', { month: 'long' })
                  }} {{
                    new Date(d).getFullYear()
                  }}
                </div>
                <v-spacer/>
                <v-btn icon small @click="monthsPicker.splice(i, 1)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </div>
            <v-row class="ma-0 text-center">
              <v-col>
                <v-btn
                  @click="dialogMonthPicker = true"
                  depressed
                >
                  {{ monthsPicker.length ? 'Reselect' : 'Select months' }}
                </v-btn>
              </v-col>
              <v-col v-if="monthsPicker.length">
                <v-btn
                  @click="monthsPicker = []"
                  depressed
                >
                  Unselect
                </v-btn>
              </v-col>
            </v-row>
            <v-text-field
              v-model="newGeneration.hours_from_registration"
              label="Hours from registration"
              type="number"
              outlined
              dense
              hide-details
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            @click="dialogGeneratePromocode = false"
            text
            color="primary"
          >
            cancel
          </v-btn>
          <v-btn
            @click="dialogGeneratePromocode = false"
            outlined
            color="primary"
          >
            generate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDatePicker"
      persistent
      max-width="290px"
    >
      <v-date-picker
        v-model="datePicker"
      >
        <v-spacer/>
        <v-btn
          @click="closeDatePicker"
          text
          color="primary"
        >
          cancel
        </v-btn>
        <v-btn
          @click="saveAndCloseDatePicker"
          :disabled="!datePicker"
          text
          color="primary"
        >
          ok
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog
      v-model="dialogMonthPicker"
      persistent
      max-width="290px"
    >
      <v-date-picker
        v-model="monthsPicker"
        type="month"
        multiple
        @change="dialogMonthPicker = false;"
      >
        <v-spacer/>
        <v-btn
          @click="dialogMonthPicker = false;"
          text
          color="primary"
        >
          close
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  name: 'Report',
  async created() {
    await Promise.all([
      this.$api.main.getRecipesSets(),
      this.$api.main.getPromocodes(),
    ]);
  },
  data: () => ({
    newGeneration: null,
    editPromocode: null,
    defaultPromocode: {
      menu_id: null,
      promocode: '',
      active: 0,
      total_price_coef: '',
      start_date: '',
      end_date: '',
      hours_from_registration: '',
    },
    defaultGeneration: {
      menu_ids: null,
      prefixes: null,
      salt_size: 4,
      total_price_coef: 0,
      start_date: '',
      end_date: '',
      hours_from_registration: 0,
    },
    headers: [
      { text: 'Promocode', value: 'promocode', width: 130 },
      { text: 'Menu', value: 'menu', width: 150 },
      { text: 'Active', value: 'active', width: 100 },
      { text: 'Total price coef', value: 'price', width: 100 },
      { text: 'Start date', value: 'start_date', width: 110 },
      { text: 'End date', value: 'end_date', width: 110 },
      { text: 'Hours from registration', value: 'time_from_registration', width: 100 },
      {
        text: 'Actions', value: 'actions', width: 100, sortable: false,
      },
    ],
    dialogDatePicker: false,
    datePicker: null,
    isSettingNewPromocodeStartDate: false,
    isSettingNewPromocodeEndDate: false,
    dialogMonthPicker: false,
    monthsPicker: [],
    dialogPromocode: false,
    dialogGeneratePromocode: false,
    editMode: null,
    datePickMode: null,
  }),
  computed: {
    promocodesData() {
      return this.$store.AppStore.promocodes;
    },
    editDialogTitle() {
      if (this.editMode === 'create') {
        return 'Creating';
      }
      return 'Editing';
    },
    editButtonTitle() {
      if (this.editMode === 'create') {
        return 'Create';
      }
      return 'Edit';
    },
  },
  methods: {
    startGeneratePromocode() {
      this.newGeneration = { ...this.defaultGeneration };
      this.dialogGeneratePromocode = true;
    },
    transformPromocode(p) {
      return {
        id: p.id || undefined,
        menu_id: p.menu_id || null,
        active: p.active === 0 ? 't' : 'f',
        promocode: p.promocode.toUpperCase() || null,
        price: p.total_price_coef || null,
        start_date: p.start_date || null,
        end_date: p.end_date || null,
        time_from_registration: p.hours_from_registration || null,
      };
    },
    async updatePromocode() {
      if (!this.editPromocode.menu_id || !this.editPromocode.promocode) {
        return;
      }
      if (this.editMode === 'create') {
        await this.$api.main.addPromocode(this.transformPromocode(this.editPromocode));
      } else {
        await this.$api.main.updatePromocode(this.transformPromocode(this.editPromocode));
      }
      if (this.dialogPromocode) {
        this.dialogPromocode = false;
      }
    },
    startCreatePromocode() {
      this.editPromocode = { ...this.defaultPromocode };
      this.editMode = 'create';
      this.dialogPromocode = true;
    },
    startEditItem(v) {
      this.editPromocode = { ...v };
      this.editMode = 'edit';
      this.dialogPromocode = true;
    },
    deleteItem(v) {
      this.$api.main.openConfirmDialog({
        text: `Are you sure you want to remove promocode ${v.promocode}?`,
        ok: async () => {
          await this.$api.main.deletePromocode(v);
        },
      });
    },
    openDatePicker(mode) {
      this.datePickMode = mode;
      if (this.datePickMode === 'start') {
        this.datePicker = this.editPromocode.start_date;
      } else {
        this.datePicker = this.editPromocode.end_date;
      }
      this.dialogDatePicker = true;
    },
    closeDatePicker() {
      this.dialogDatePicker = false;
    },
    saveAndCloseDatePicker() {
      if (this.datePickMode === 'start') {
        this.editPromocode.start_date = this.datePicker;
      } else {
        this.editPromocode.end_date = this.datePicker;
      }
      this.dialogDatePicker = false;
    },
    sort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (!sortBy[0]) {
          return 0;
        }
        if (sortBy[0] === 'menu') {
          const menuA = this.$store.AppStore.menus.find((el) => el.id === a.menu_id)?.name;
          const menuB = this.$store.AppStore.menus.find((el) => el.id === b.menu_id)?.name;
          if (!sortDesc[0]) {
            return menuA.localeCompare(menuB);
          }
          return menuB.localeCompare(menuA);
        }
        if (['price', 'time_from_registration'].includes(sortBy[0])) {
          if (!sortDesc[0]) {
            return a[sortBy[0]] - b[sortBy[0]];
          }
          return b[sortBy[0]] - a[sortBy[0]];
        }
        if (!sortDesc[0]) {
          return (a[sortBy[0]] || '').toString().localeCompare((b[sortBy[0]] || '').toString());
        }
        return (b[sortBy[0]] || '').toString().localeCompare((a[sortBy[0]] || '').toString());
      });
      return items;
    },
    getMenuName(menuId) {
      return this.$store.AppStore.menus.find((el) => el.id === menuId)?.name;
    },
  },
};
</script>

<style scoped>
.block {
  margin-top: 16px;
  margin-bottom: 16px;
}

.item-menu {
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
.card-form .v-text-field{
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
